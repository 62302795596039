// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buscador-js": () => import("./../../../src/pages/buscador.js" /* webpackChunkName: "component---src-pages-buscador-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-templates-career-feeds-career-health-speciality-js": () => import("./../../../src/templates/careerFeeds/careerHealthSpeciality.js" /* webpackChunkName: "component---src-templates-career-feeds-career-health-speciality-js" */),
  "component---src-templates-career-feeds-career-js": () => import("./../../../src/templates/careerFeeds/career.js" /* webpackChunkName: "component---src-templates-career-feeds-career-js" */),
  "component---src-templates-career-page-js": () => import("./../../../src/templates/career-page.js" /* webpackChunkName: "component---src-templates-career-page-js" */),
  "component---src-templates-diaries-feeds-diaries-all-js": () => import("./../../../src/templates/diariesFeeds/diaries-all.js" /* webpackChunkName: "component---src-templates-diaries-feeds-diaries-all-js" */),
  "component---src-templates-diaries-feeds-diaries-by-category-js": () => import("./../../../src/templates/diariesFeeds/diaries-byCategory.js" /* webpackChunkName: "component---src-templates-diaries-feeds-diaries-by-category-js" */),
  "component---src-templates-diaries-feeds-diaries-ou-all-js": () => import("./../../../src/templates/diariesFeeds/diaries-ou-all.js" /* webpackChunkName: "component---src-templates-diaries-feeds-diaries-ou-all-js" */),
  "component---src-templates-diaries-feeds-diaries-ou-by-category-js": () => import("./../../../src/templates/diariesFeeds/diaries-ou-byCategory.js" /* webpackChunkName: "component---src-templates-diaries-feeds-diaries-ou-by-category-js" */),
  "component---src-templates-diary-page-js": () => import("./../../../src/templates/diary-page.js" /* webpackChunkName: "component---src-templates-diary-page-js" */),
  "component---src-templates-internal-page-js": () => import("./../../../src/templates/internal-page.js" /* webpackChunkName: "component---src-templates-internal-page-js" */),
  "component---src-templates-landing-pages-landing-page-js": () => import("./../../../src/templates/landingPages/landing-page.js" /* webpackChunkName: "component---src-templates-landing-pages-landing-page-js" */),
  "component---src-templates-main-render-ou-js": () => import("./../../../src/templates/main-render-ou.js" /* webpackChunkName: "component---src-templates-main-render-ou-js" */),
  "component---src-templates-news-feeds-news-all-js": () => import("./../../../src/templates/newsFeeds/news-all.js" /* webpackChunkName: "component---src-templates-news-feeds-news-all-js" */),
  "component---src-templates-news-feeds-news-by-category-js": () => import("./../../../src/templates/newsFeeds/news-byCategory.js" /* webpackChunkName: "component---src-templates-news-feeds-news-by-category-js" */),
  "component---src-templates-news-feeds-news-ou-all-js": () => import("./../../../src/templates/newsFeeds/news-ou-all.js" /* webpackChunkName: "component---src-templates-news-feeds-news-ou-all-js" */),
  "component---src-templates-news-feeds-news-ou-by-categories-js": () => import("./../../../src/templates/newsFeeds/news-ou-byCategories.js" /* webpackChunkName: "component---src-templates-news-feeds-news-ou-by-categories-js" */),
  "component---src-templates-note-page-js": () => import("./../../../src/templates/note-page.js" /* webpackChunkName: "component---src-templates-note-page-js" */),
  "component---src-templates-notes-feeds-notes-all-js": () => import("./../../../src/templates/notesFeeds/notes-all.js" /* webpackChunkName: "component---src-templates-notes-feeds-notes-all-js" */),
  "component---src-templates-novelties-page-js": () => import("./../../../src/templates/novelties-page.js" /* webpackChunkName: "component---src-templates-novelties-page-js" */)
}

